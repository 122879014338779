

// components
import ContactForm from "../components/ContactForm";

export default function Advertise() {

  return (
    <div className="p-10 grid md:grid-cols-2 gap-8">
      <div>
        <h1 className="text-left font-normal">
          Advertise With Us
        </h1>
        <p>
          Here at Happy Radio we are all about championing everything local across Manchester and Cheshire, so if you have a business and you want to increase your brand awareness and tell everyone about your business - Come and advertise with us. 
        <br></br>
        <br></br>
          We make a point of highlighting our adverts which are all relatable to our listeners, so come and join the family, it's not unaffordable and it will create some great awareness for you. 
        <br></br>
        <br></br>
          For more information fill in the contact form. 
        </p>
      </div>
      <div>
        <ContactForm />
      </div>
    </div>
  )
}
