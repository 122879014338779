
//assets
// import applelogo from '../assets/logos/app-store.png'
// import googlelogo from '../assets/logos/play-store.png'
// import web from '../assets/howtolisten/Happy-Website.JPG'
// import app from '../assets/howtolisten/Happy-App.JPG'
// import tv from '../assets/howtolisten/Happy-TV.JPG'
// import car from '../assets/howtolisten/Happy-Car.JPG'
// import google from '../assets/howtolisten/Happy-Google.JPG'
// import alexa from '../assets/howtolisten/Happy-Alexa.JPG'
// import radioplayer from '../assets/howtolisten/Happy-Radio-Player.JPG'
// import sonos from '../assets/howtolisten/Happy-Sonos.JPG'

export default function HowToListen() {
  return (
    <div className="py-10 max-w-6xl mx-auto space-y-4">
      <h1>6 Weeks of Summer online Promotion on Happy Radio 2024 - Specific Rules</h1>
      <p className="text-center font-light pb-8">Prizes may only be redeemed once, may not be exchanged for cash, replaced  if lost, redeemed in one sitting and is non-transferable.</p>
      <h2>Competition Promotion</h2>
      <p><strong>Promotion</strong> is organised by Happy Radio. The Promotion will run from 22nd July 2024 to 30th August 2024 on the Happy Radio network. <a href="#terms-and-conditions">Terms and Conditions</a></p>

      <h3>IMPORTANT</h3>
      <p>The Promotion is subject to these Specific Rules and the General Terms and Conditions which are available above. Entry into the Promotion constitutes acceptance of these Specific Rules and the General Terms and Conditions.</p>

      <h4>Details of the Promotion:</h4>
      <ol>
        <li>To enter the Promotion, you must text 'Happy Summer' to 81400. Texts charged at your standard rate.</li>
        <li>Text entry will open at 07.00 on Monday 22nd July and close at 12 Noon on the Friday of each week. Entries received before or after this time will not be included in the weekly Promotion.</li>
        <li>Within 7 days of the closing time of the Promotion, all eligible entries will be entered into a draw. One correct entry will be selected at random, and the winning entrant will be contacted by one of the Happy Radio representatives via email or telephone on the details on which you entered. If such winner does not respond within 7 days from being contacted, they will forfeit their right to the prize, and we shall be entitled to select another winner by another random draw. That subsequent winner must respond to the notification email within 7 days of the date on which they are notified, or they will also forfeit the prize.</li>
        <li>We may publish and publicise your name, your nearest geographical location (for example, your nearest city, town or county) and your entry, and we may refer to your and any applicable third party's association with the Promotion and/or the prize, in any and all media, worldwide, in perpetuity, for publicity and PR purposes solely in connection with the Promotion.</li>
        <li>In relation to winners (and runners up if applicable), you understand that we and our promotion partner may: (i) interview you and your guest which we may film, photograph and/or record and; (ii) film, photograph and/or record, you and your guest in connection with the Promotion, either by telephone or in person, and we may use such films, recordings and/or photographs for publicity and PR purposes in any and all media, worldwide, as often and for as long as we consider appropriate or desirable, in connection with the Promotion. We may also publish your and your guests name, image, social media handle and/or profile picture.</li>
      </ol>

      <h4>Eligibility:</h4>
      <ul>
        <li>The Promotion is only open to residents of the UK aged 18 and over, and who are based in the UK at the time of entry.</li>
        <li>Entry is restricted to one entry per person; duplicate entries will be excluded from the Promotion.</li>
        <li>The guest of the winning entrant must be aged 18 or over</li>
      </ul>

      <h4>Prize:</h4>
      <p>One winning entrant weekly will receive the following:</p>
      <ul>
        <li>2 x Wristbands to 'Bright Lights, Big Fun' Splash Park & Fair at The Trafford Centre (12 Wristbands in total) (Value £44)</li>
        <li>4 x Tickets to Either Chaos Karts or The Crystal Maze (Alternating Weeks) (Value: up to £264 (Crystal Maze) up to £140 (Chaos Karts))</li>
        <li>4 x Intergalactic Skating Passes to Paradise Skateworld (Value: Up to £80)</li>
        <li>4 x Tickets to Junkyard Golf (Value: Up To £66)</li>
      </ul>

      <h4>Prize Terms and Conditions:</h4>
      <ul>
        <li>These terms and conditions apply to the Happy Radio competition Prize may only be redeemed once, may not be exchanged for cash, replaced if lost, redeemed in one sitting and is non-transferable.</li>
        <li>All prizes must be claimed and booked by the prize winner within 28 days from the date of the prize provider's initial correspondence with them.</li>
        <li>You acknowledge and agree that, without limitation to the provisions of Clause 12 of the General Terms & Conditions, our ability to fulfil a prize and/or perform any of our obligations under the Promotion Terms and Conditions may differ due to factors beyond our control.</li>
        <li>Any other costs and expenses which are not specifically included in the prize description are excluded and will be the sole responsibility of the winner. By way of example, food, transport to and from the hotel are not included within the prize.</li>
        <li>We reserve the right to substitute the prize with another prize of equal or greater value at our absolute discretion.</li>
        <li>For all enquiries in connection with the Promotion please contact Happy Radio at <a href="mailto:studio@happyradiouk.com">studio@happyradiouk.com</a></li>
      </ul>

      <h4>Data Protection:</h4>
      <ul>
        <li>Your personal data that you provide to us will be collected and processed by us, our licensees (including Communicorp UK Limited) as well as the prize providers, and if applicable, our service providers, network operators and suppliers in order to administer and fulfil the Promotion. Your personal data may also be disclosed to the Phone-paid Services Authority, Ofcom and/or the Advertising Standards Authority at their reasonable request for regulatory purposes, for example, in order to prevent, investigate and detect crime, fraud or anti-social behaviour and comply with law enforcement agencies and applicable advertising codes.</li>
        <li>By entering a Promotion, you acknowledge that any personal data provided by you in connection with the Promotion will be processed as set out above and in accordance with our Privacy Policy which sets out full details of how we process personal data and how you can exercise your rights as a data subject. Please ensure you have read and understood our <a href="#privacy-policy">Privacy Policy</a> before entering any Promotion. If you are required to submit a guest(s) personal data to enter a Promotion, you must ensure that your guest/companion(s) have given you consent to provide us with their personal data.</li>
        <li>You may be asked if you want to opt-in to marketing from us when you enter a Promotion. If you choose to opt-in, we will use your personal data for marketing purposes (see Privacy Policy for more details). You can withdraw your consent to marketing at any time by unsubscribing.</li>
        <li>We and the prize providers may transfer and process personal data outside of the EEA and UK for the purposes of administering the Promotion. Where any transfers are made outside of the EEA or UK we ensure appropriate safeguards to protect your personal data are in place.</li>
        <li>We will hold your personal data for a period of up to 3 years in order to comply with our regulatory obligations.</li>
        <li>You may request the removal of your personal details from our database by <a href="mailto:studio@happyradiouk.com">studio@happyradiouk.com</a>. If you request that your personal details be removed prior to the conclusion of a Promotion, you will forfeit your right to enter the Promotion and/or claim any prize and we reserve the right to select another entrant to participate in the Promotion or withdraw the prize and select another winner, as applicable.</li>
      </ul>

      <h3>IMPORTANT</h3>
      <p>The Promotion is subject to these Specific Rules and the General Terms and Conditions which are available above. Entry into the Promotion constitutes acceptance of these Specific Rules and the General Terms and Conditions.</p>

      <h4>Details of the Promotion:</h4>
      <p>To enter the Promotion, you must text 'Happy Summer' to 81400. Texts charged at your standard rate.</p>
      <p>Text entry will open at 07.00 on Monday 22nd July 2024 and close at 12 noon on each Friday up until Friday 30th August 2024. Entries received before or after this time will not be included in the daily Promotion.</p>
    </div>
  )
}
