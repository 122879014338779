import Presenter from '../components/Presenter'

export default function Presenters() {
  return (
    <div className="py-10">
      <h1>Happy Radio Presenters</h1>
      <p className="text-center font-light pb-8">Discover more about the people behind the mic on Happy Radio</p>
      <Presenter />
    </div>
  )
}
