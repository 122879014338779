

export default function NotFound() {

  return (
    <div className="p-10">
      <div>
        <h1 className="text-left font-normal">
          404 Page Not Found
        </h1>
        <p>
          Sorry we could not locate the page you were looking for.
        </p>
      </div>
    </div>
  )
}
