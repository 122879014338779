import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { sponsorData } from "../data/sponsorData";

export default function Sponsors() {
  return (
    <div>
      <Carousel
        centerMode
        centerSlidePercentage={25}
        autoPlay
        labels
        infiniteLoop
        showThumbs={false}
        useKeyboardArrows
        interval={1500}
        showIndicators={false}
      >
        {sponsorData.map((sponsor) => (
          <a href={sponsor.url}>
            <div>
              <img
                src={sponsor.src}
                alt="Happy Radio UK Sponsors"
                width={100}
                height={100}
              />
            </div>
          </a>
        ))}
      </Carousel>
    </div>
  );
}
