import { useParams, useNavigate, Link } from "react-router-dom"

// data
import { presenterData } from "../data/presenterData"

export default function Profile() {
  const { id } = useParams()
  const navigate = useNavigate()

  // Find a specific object from an array by matching an ID 
  const presenter = presenterData.find(presenter => presenter.id === Number(id))

  return (
    <div className="bg-grey-100 text-grey-700 min-h-[360px] flex justify-center relative">
      <Link className="absolute left-2 top-2 md:left-4 md:top-4 text-sm text-black hover:text-black  hover:underline" to="/presenters">&larr; Back to presenters</Link>
      <div className="grid md:grid-cols-2 text-center md:text-left justify-between items-center gap-6 p-8 max-w-6xl">
        <div>
          <h1>{presenter.name}</h1>
          <p className="text-grey-500 text-sm">
            {presenter.description}
          </p>
        </div>
        <div className="justify-self-center md:justify-self-end">
          <img 
            src={presenter.profileImage} 
            alt={presenter.name} 
            height={360} 
            width={360} 
            className="h-40 w-40 md:w-60 md:h-60 rounded-full object-cover"
          />
        </div>
      </div>
    </div>
  )
}
