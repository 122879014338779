
// pages & components
import Scheduler from '../components/Scheduler'

export default function Schedule() {
  return (
    <div className="py-10">
      <h1>Happy Radio Schedule</h1>
      <Scheduler />
    </div>
  )
}
