//assets
import apple from '../assets/logos/app-store.png'
import google from '../assets/logos/play-store.png'

export default function Contact() {
  return (
    <div className="p-10 grid md:grid-cols-2 gap-8">
      <div>
        <h1 className="text-3xl mb-6">
          Contact Us
        </h1>
        <p>
          Contact us if it's for a mention, a birthday or an event that you'd like us to talk about on Happy Radio then drop us an email with the details, we'd love to hear from you - 
          <a 
            className="link"
            href="mailto:studio@happyradiouk.com?subject=Happy Radio UK"
            >studio@happyradiouk.com
          </a>
        </p>
      </div>
      <div>
        Alternatively you can contact us via social media
        <br></br>
        <br></br>
        <div className="social-icons flex justify-start gap-6">
          <a href="https://www.facebook.com/happyradiouk/" target="_blank" rel="noreferrer"><svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook" className="svg-inline--fa fa-facebook w-8 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" aria-label="hitsradiouk Facebook"><path fill="currentColor" d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"></path></svg> </a>
          <a href="https://www.twitter.com/happyradiouk/" target="_blank" rel="noreferrer"><svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter-square" className="svg-inline--fa fa-twitter-square w-8 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" aria-label="HitsRadioUK Twitter"><path fill="currentColor" d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-48.9 158.8c.2 2.8.2 5.7.2 8.5 0 86.7-66 186.6-186.6 186.6-37.2 0-71.7-10.8-100.7-29.4 5.3.6 10.4.8 15.8.8 30.7 0 58.9-10.4 81.4-28-28.8-.6-53-19.5-61.3-45.5 10.1 1.5 19.2 1.5 29.6-1.2-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7 4.9 18.9 7.9 29.6 8.3a65.447 65.447 0 0 1-29.2-54.6c0-12.2 3.2-23.4 8.9-33.1 32.3 39.8 80.8 65.8 135.2 68.6-9.3-44.5 24-80.6 64-80.6 18.9 0 35.9 7.9 47.9 20.7 14.8-2.8 29-8.3 41.6-15.8-4.9 15.2-15.2 28-28.8 36.1 13.2-1.4 26-5.1 37.8-10.2-8.9 13.1-20.1 24.7-32.9 34z"></path></svg> </a>
          <a href="https://www.instagram.com/happyradio.uk/" target="_blank" rel="noreferrer"><svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram" className="svg-inline--fa fa-instagram w-8 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" aria-label="HitsRadioUK Instagram"><path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path></svg> </a>
        </div>
        <br></br>
        Or text us on '81400' starting your message with the word HAPPY 
        <br></br>
        <br></br>
        <hr />
        <br></br>
        <h2 className='text-xl font-bold mb-6'>Download the Happy Radio UK App</h2>
        <div className='flex gap-8'>
          <a href="https://apps.apple.com/gb/app/happy-radio-uk/id1615406580" target="_blank" rel="noreferrer">
            <img src={apple} alt="App Store" width={200} height={200} />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.happyradiouk.player" target="_blank" rel="noreferrer">
            <img src={google} alt="Play Store" width={200} height={200} />
          </a>
        </div>
      </div>
    </div>
  )
}
