// assets
import banner from '../assets/banners/banner6.jpg'
import mobileBanner from '../assets/banners/mobile-banner.jpg'

export default function Banner() {
  return (
    <div className="flex flex-col items-center justify-center relative">
    <img 
      className="hidden md:block bg-cover bg-center bg-no-repeat object-cover min-h-[100px]" //  
      src={banner} 
      alt="Landing Page Banner" 
      width={2400}
      height={600}
    />
    <img 
      className="md:hidden bg-cover bg-center bg-no-repeat object-cover min-h-[100px]" //  
      src={mobileBanner} 
      alt="Landing Page Banner"
      width={800}
      height={200}
    />
    {/* <p className="absolute uppercase tracking-widest bg-white text-xs md:text-2xl rounded-full px-6 py-2 font-semibold">Listen Live &#9654;</p> */}
    {/* <p className="absolute tracking-wider bg-neutral-900 text-xs md:text-sm text-white rounded-full px-6 py-2 mt-24 md:mt-48">On air now: Non Hits throwback</p>  */}
  </div>
  )
}
