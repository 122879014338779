import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

// assets
import logo from '../assets/logos/logo-100.png'

// data
// import { eventsData } from '../data/eventsData'
// import { hour } from './Scheduler'

export default function Player() {
  return (
    <div className="player">
      <img src={logo} alt='Happy Radio UK Logo' className='bg-theme' width={80} height={80}/>
      {/* <img src='/schedule.jpg' alt='Happy Radio UK Presenter' width={50} height={50}/> */}
      {/* {eventsData.map((event) => {
          return event.time == hour ?
          (
            <div key={event.id} className="pl-4">
              <p className="mb-1 text-sm font-semibold">{event.name}</p>
              <p className="text-xs md:text-sm text-gray-500">{event.description}</p>
            </div>
          )
        : null })} */}
      
      {/* <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="play" className="svg-inline--fa fa-play w-8 ml-auto" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"></path></svg> */}
      <AudioPlayer
          autoPlay={true}
          src="https://stream.aiir.com/yofqmvmgihuuv"
          controls
          customAdditionalControls={[]}
          layout="horizontal"
          showJumpControls={false}
          defaultCurrentTime=""
          defaultDuration=""
        />
    </div>
  )
}
