export let eventsData = [

// Weekday Schedule
  {
    id: 23,
    name: 'Paul Cambray',
    date: null,
    day: ['Mon' , 'Tue' , 'Wed' , 'Thu' , 'Fri'],
    time: 5,
    image: '/schedule/Paul-Cambray-Mon-Fri-5am.jpeg',
    description: `Join our early morning show with Paul Cambray whatever reason you're up at that time of day Paul has just the right mix of chat and great music.`
  },
  {
    id: 1,
    name: 'Spence Macdonald',
    date: null,
    day: ['Mon' , 'Tue' , 'Wed' , 'Thu' , 'Fri'],
    time: 7,
    image: '/schedule/Spence-Macdonald-Weekday-Breakfast.JPG',
    description: `Spence has the perfect start to your day to put you in a good mood with his funny stories and surveys and of course The Songs That Make You Smile.`
  },
  {
    id: 2,
    name: 'Darren Proctor',
    date: null,
    day: ['Mon' , 'Tue' , 'Wed' , 'Thu', 'Fri'],
    time: 10,
    image: '/schedule/Darren-Proctor-Monday-Friday-Midmornings.jpeg',
    description: `Darren keeps you up to date with everything that's going on locally across Manchester and Cheshire and makes you part of the show!`
  },
  {
    id: 3,
    name: 'Steve Penk',
    date: null,
    day: ['Mon' , 'Tue' , 'Wed' , 'Thu' , 'Fri'],
    time: 13,
    image: '/schedule/Steve-Penk-Mon-Friday.JPG',
    description: `The legend that is Steve Penk has an hour of classic songs that we love on The Rewind from 1 till 2, then it's time for a bit of fun with the Windup Calls and those Songs That Make You Smile`
  },
  {
    id: 4,
    name: "Lauren's Happy Hour",
    date: null,
    day: ['Mon' , 'Tue' , 'Wed' , 'Thu' , 'Fri'],
    time: 15,
    image: '/schedule/Lauren Happy Hour Mon-Fri 3pm.JPG',
    description: `Join the Cheshire Housewives favourite Lauren Simon playing an hour of her favourite music to brighten up your afternoon`
  },
  {
    id: 5,
    name: 'Drivetime with Ian',
    date: null,
    day: ['Mon' , 'Tue' , 'Wed' , 'Thu' , 'Fri'],
    time: 16,
    image: '/schedule/Ian & Fay Drivetime.jpeg',
    description: `Ian and Faye drive you home with our friends at Chapelhouse Motorgroup each afternoon  playing the songs that make you smile and keeping you informed and updated on the traffic situation across Manchester and Cheshire.`
  },
  {
    id: 6,
    name: 'The Evening Show With Fiona Sadler',
    date: null,
    day: ['Mon' , 'Tue' , 'Wed' , 'Thu'],
    time: 19,
    image: '/schedule/Fiona Sadler Evenings.JPG',
    description: `
    She's warm she's chatty, she's a lover of great music - join Fiona every evening on Happy Radio every evening from 7-10pm and you'll find yourself a new best friend!
    `
  },
  {
    id: 22,
    name: 'The Late Show With Andy Hoyle',
    date: null,
    day: ['Mon' , 'Tue' , 'Wed' , 'Thu'],
    time: 22,
    image: '/schedule/Andy Hoyle Evenings.JPG',
    description: `
    Andy fills your late evening with a huge selection of the 'Songs That Make You Smile' great company and the best music in the world every evening from 10pm-1am
    `
  },
  {
    id: 24,
    name: 'Up All Night',
    date: null,
    day: ['Mon', 'Tue' , 'Wed' , 'Thu'],
    time: 1,
    image: '/schedule/Happy Radio.jpeg',
    description: `
    Can't sleep? Neither can the music! From classics to currents, you have The Songs That  Make You Smile keeping you company right through the night.
    `
  },

// Friday Schedule
  {
    id: 7,
    name: `Charlie Cee – Charlie's Jams`,
    date: null,
    day: ['Fri'],
    time: 22,
    image: '/schedule/Charlies-Jams-Friday-Sunday-10pm.JPG',
    description: `Join the king of Soul R&B AND Motown every Friday/Sunday for the best jams in the world!`
  },
  {
    id: 8,
    name: 'Disco Di',
    date: null,
    day: ['Fri'],
    time: 19,
    image: '/schedule/Disco-Di-Friday-Night.JPG',
    description: `It's time to kick off Manchester's Weekend! And what better way to do it than with the disco queen of the north Disco Di!`
  },

// Saturday Schedule
  {
    id: 9,
    name: 'Sean Goldsmith',
    date: null,
    day: ['Sat'],
    time: 7,
    image: '/schedule/Sean Goldsmith Weekend Breakfast.jpeg',
    description: `Ease your way into you Saturday/Sunday morning with the dulcet tones of Sean Goldsmith, guaranteed to get you set up for your weekend `
  },
  {
    id: 10,
    name: 'Chelsea Norris',
    date: null,
    day: ['Sat'],
    time: 10,
    image: '/schedule/Chelsea-Midmornings-Saturday.JPG',
    description: `Join Chelsea every Saturday to keep you company with local news the Songs That Make You Smile and your requests. `
  },
  {
    id: 11,
    name: 'Sam Walker At The Weekend',
    date: null,
    day: ['Sat'],
    time: 12,
    image: '/schedule/Made-In-Machester.JPG',
    description: `Sam Walker brightening up your Saturday and Sunday afternoons with great company and the songs that make you smile!!`
  },
  {
    id: 12,
    name: 'Sports Round up with Andy Hoyle',
    date: null,
    day: ['Sat'],
    time: 14,
    image: '/schedule/Andy Hoyle - Saturday Sports 2pm.JPG',
    description: `Andy Hoyle making us feel like it's the weekend with roundup news of the weekends sport, activity and events across Manchester, Cheshire and The North West. Along with your Saturday afternoon Songs That Make You Smile soundtrack.
    `
  },
  {
    id: 13,
    name: `Pete Price's Guilty Pleasures`,
    date: null,
    day: ['Sat'],
    time: 18,
    image: '/schedule/Guilty-Pleasures-Saturday.jpeg',
    description: `Time to start your Saturday night party – get ready for all the songs you forgot you loved!`
  },
  {
    id: 14,
    name: "Darren Proctor's Grown Up Disco",
    date: null,
    day: ['Sat'],
    time: 20,
    image: '/schedule/Darren-Proctors-Grown-Up-Disco.JPG',
    description: `We've got 2 hours of the club classics we've grown up with! It's a party round at your place.`
  },
  {
    id: 22,
    name: "Happy Radio Old Skool",
    date: null,
    day: ['Sat'],
    time: 22,
    image: '/schedule/Happy Radio.jpeg',
    description: `Relive your best life and take a trip down memory lane with the songs you loved from back in the day. whether you were at the Hacienda, Mr Smiths, Quaffers, Paradise Factory or at your college disco we'll take you back there with non stop anthems for two hours...`
  },
  
// Sunday Schedule
  {
    id: 15,
    name: 'Sean Goldsmith',
    date: null,
    day: ['Sun'],
    time: 7,
    image: '/schedule/Sean Goldsmith Weekend Breakfast.jpeg',
    description: `Those big dancefloor anthems continue non stop – all part of Manchester's Weekend.`
  },
  {
    id: 16,
    name: 'Crone zone with Paul Crone',
    date: null,
    day: ['Sun'],
    time: 10,
    image: '/schedule/Paul-Crone-Sunday-10am.JPG',
    description: `You've seen him on the TV on Granada Reports, now you can listen Paul Crone every Sunday morning – join him to hear his funny stories of the week and become a Cronce Zoner!`
  },
  {
    id: 17,
    name: 'Sam Walker At The Weekend',
    date: null,
    day: ['Sun'],
    time: 12,
    image: '/schedule/Made-In-Machester.JPG',
    description: `Sam Walker brightening up your Saturday and Sunday afternoons with great company and the songs that make you smile!!`
  },
  {
    id: 18,
    name: 'Spence Macdonald',
    date: null,
    day: ['Sun'],
    time: 14,
    image: '/schedule/Spence-Macdonald-Sunday.jpeg',
    description: `Spend your Sunday afternoon with Spence Macdonald, filling your afternoon with his funny stories, observations and of course playing the songs that make you smile.`
  },
  {
    id: 19,
    name: 'Happy Radio Chart Show with Ian Finch',
    date: null,
    day: ['Sun'],
    time: 16,
    image: '/schedule/Happy-Chart-Show.JPG',
    description: `Ian takes us back to two different decades every week and counts down that years Songs That Make Us Smile.`
  },
  {
    id: 20,
    name: 'Happy Radio Chilled',
    date: null,
    day: ['Sun'],
    time: 18,
    image: '/schedule/Happy-Radio-Chilled-Sunday.jpeg',
    description: `Andy wraps up the weekend nicely where we can just kick back and enjoy his great selection of music`
  },
  {
    id: 21,
    name: `Charlie Cee – Charlie's Jams`,
    date: null,
    day: ['Sun'],
    time: 22,
    image: '/schedule/Charlies-Jams-Friday-Sunday-10pm.JPG',
    description: `Join the king of Soul R&B AND Motown every Friday/Sunday for the best jams in the world!`
  }
]
