import { Link, NavLink } from 'react-router-dom'

// assets
import logo from '../assets/logos/logo-1000-rec.png'

// components
// import Search from '../components/Search'

const handleMenu = () => {
  const menu = document.querySelector('#menu');

  if (menu.classList.contains('hidden')) {
    menu.classList.remove('hidden');
  } else {
    menu.classList.add('hidden')
  }
}

export default function Navbar() {
  return (
    <nav className="nav-container">
      <div className="logo">
        <Link to="/">
          {/* <img src={'https://assets.planetradio.co.uk/img/ConfigWebHeaderLogoSVGImageUrl/354.svg?ver=1537347237'} alt="Happy Radio UK Logo" width={120} height={120} /> */}
          <img src={logo} alt="Happy Radio UK Logo" width={160} height={160} />
          {/* <span>Happy Radio UK</span> */}
        </Link>
      </div>
      <div>
        <div className="burger" onClick={handleMenu}>
          <svg className="ml-auto w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
        </div>
        <ul className="hidden nav-menu" id="menu">
          <NavLink to="/">
              <li
                className="nav-menu-item">
                Home
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
              </li>
          </NavLink>
          <NavLink to="/schedule">
              <li
                className="nav-menu-item">
                Schedule
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
              </li>
          </NavLink>
          <NavLink to="/presenters">
              <li
                className="nav-menu-item">
                Presenters
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
              </li>
          </NavLink>
          <NavLink to="/how-to-listen">
              <li
                className="nav-menu-item">
                How To Listen
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"></path><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
              </li>
          </NavLink>
          <NavLink to="/advertise">
              <li
                className="nav-menu-item">
                Advertise
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"></path></svg>              
              </li>
          </NavLink>
          <NavLink to="/contact">
              <li
                className="nav-menu-item">
                Contact
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"></path></svg>
              </li>
          </NavLink>
        </ul>
        {/* <Search />   */}
      </div>
      <div></div>
    </nav>
  )
}
