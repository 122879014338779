// assets
import Sponsor1 from "../assets/sponsors/Sealiions sponsor.JPEG";
import Sponsor2 from "../assets/sponsors/chapelhouse-slider.png";
import Sponsor3 from "../assets/sponsors/spa-seven.JPG";
import Sponsor4 from "../assets/sponsors/smile-stylist.jpeg";
import Sponsor5 from "../assets/sponsors/offyourbox.JPG";
import Sponsor6 from "../assets/sponsors/minnieandteds.JPG";
import Sponsor7 from "../assets/sponsors/New York New York Sponsor Logo.JPEG";
import Sponsor8 from "../assets/sponsors/curves.JPG";
import Sponsor9 from "../assets/sponsors/Margaret Dabbs Sponsor logo.jpeg";
import Sponsor10 from "../assets/sponsors/melanomauk.JPG";
import Sponsor11 from "../assets/sponsors/franklyn.JPG";
import Sponsor12 from "../assets/sponsors/lshauto.PNG";

export const sponsorData = [
  // {
  //   src: Sponsor1,
  //   url: "https://sealions.com",
  // },
  {
    src: Sponsor2,
    url: "https://www.chapelhouse.co.uk/",
  },
  {
    src: Sponsor3,
    url: "https://www.spaseven.co.uk/",
  },
  {
    src: Sponsor4,
    url: "https://www.smilestylist.co.uk",
  },
  // {
  //   src: Sponsor5,
  //   url: "https://www.offyourbox.uk/",
  // },
  {
    src: Sponsor6,
    url: "https://minnieandteds.co.uk/",
  },
  {
    src: Sponsor7,
    url: "https://www.newyorknewyorkbar.co.uk/",
  },
  // {
  //   src: Sponsor8,
  //   url: "https://wearecurves.com/",
  // },
  {
    src: Sponsor9,
    url: "https://www.margaretdabbs.com/",
  },
  // {
  //   src: Sponsor10,
  //   url: "https://www.melanomauk.org.uk/",
  // },
  {
    src: Sponsor11,
    url: "https://www.franklynfm.co.uk/",
  },
  {
    src: Sponsor12,
    url: "https://www.lshauto.co.uk/",
  },
];
