import { useEffect, useState } from "react"
import XMLParser from "react-xml-parser"
// import { formatDistance, subDays, parse, parseISO, toDate } from 'date-fns'

// components
// import { Tabs } from './Tabs';

//assets
import NewsLogo from '../assets/logos/i-love-mcr-logo.png'
// import NewsPlaceholder from '/news-placeholder.jpg'


export default function News() {
  const [items, setItems] = useState([])

  useEffect(() => {
    fetch('/api/iLoveMCRNews')
    .then(response => response.text())
    .then(data => {
      var xml = new XMLParser().parseFromString(data)
      // console.log(xml);
      const channel = xml.getElementsByTagName('channel')
      // console.log(channel);
      const items = channel[0].getElementsByTagName('item')
      setItems(items)
      console.log(items)
    })
    .catch(err => {
      console.log(err.message)
    });
  }, [])

  return (
    <div>
      <div className='flex justify-center items-center font-light gap-2'>
        Powered by 
        <a href="https://ilovemanchester.com/" target="_blank" rel="sponsored noreferrer external">
          <img src={NewsLogo} alt="i Love Manchester News Logo" height={140} width={140} />
        </a>
      </div>
      <div className="news mt-8">
        {/* <Tabs /> */}
        {items 
        ? items.slice(0, 8).map(item => (
          <a href={item.children[1].value} target="_blank" rel="sponsored noreferrer external" key={item.children[1].value}>
            <div className="news-card">
              {/* <img src={item.children[10].attributes.url.replace('70x70', '384x216')} alt={item.children[6].value} /> */}
              <img 
                src={item.children.find(child => child.name === 'description').value.match(/src="([^"]*)/)?.[1] ?? null} 
                alt={item.children[0].value} height={600} width={600} 
              />
              <div className="mx-4">
                <h3 className="font-bold text-gray-700 text-sm mb-4">{item.children[0].value.replace(/%20/g, ' ')}</h3>
                <p className="text-gray-500 text-sm mt-auto">
                  {item.children[5].value.slice(0, -2)} | {item.children[4].value.slice(0, -9)}
                </p>
              </div>
            </div>
          </a>
        ))
        : <h2>Loading...</h2>
      }
      </div>
    </div>
  )
}